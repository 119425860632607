import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from 'yoshi-flow-editor-runtime/tpa-settings';

type IStylesParams = {
  cart_background: StyleParamType.Color;
  cart_dividersColor: StyleParamType.Color;
  cart_textColor: StyleParamType.Color;
  cart_textFontStyle: StyleParamType.Font;
  cart_titlesColor: StyleParamType.Color;
  cart_titlesFontStyle: StyleParamType.Font;
  cart_linksColor: StyleParamType.Color;

  cartButton_borderColor: StyleParamType.Color;
  cartButton_backgroundColor: StyleParamType.Color;
  cartButton_textColor: StyleParamType.Color;
  cartButton_textFont: StyleParamType.Font;
};

export default createStylesParams<IStylesParams>({
  cart_background: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  cart_titlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cart_titlesFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  cart_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cart_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cart_dividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  cart_linksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  cartButton_borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cartButton_backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  cartButton_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  cartButton_textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
});
