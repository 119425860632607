import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {CartItem} from './CartItem/CartItem';
import {CommonProductLineItem} from './ProductLineItem/ProductLineItem';

export enum CartItemsDataHook {
  'root' = 'CartItemsDataHook.root',
  'item' = 'CartItemsDataHook.item',
}

export function CartItems() {
  const {shouldUseCommonProductLineItem, cart} = useControllerProps().cartStore;

  return (
    <div data-hook={CartItemsDataHook.root}>
      <ul>
        {cart.items.map((item) => (
          <li key={`cart-item-${item.cartItemId}`} data-hook={CartItemsDataHook.item}>
            {shouldUseCommonProductLineItem ? <CommonProductLineItem item={item} /> : <CartItem item={item} />}
          </li>
        ))}
      </ul>
    </div>
  );
}
