import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {SectionNotification} from 'wix-ui-tpa/SectionNotification';
import {useTranslation} from 'yoshi-flow-editor-runtime';

export const DeliveryNotification = () => {
  const {t} = useTranslation();

  return (
    <div data-hook={OrderSummaryDataHook.DeliveryNotificationRoot}>
      <dl>
        <dt>
          <SectionNotification data-hook={OrderSummaryDataHook.DeliveryNotificationMessage}>
            <SectionNotification.Text>{t('cart.note.shipping.atCheckout')}</SectionNotification.Text>
          </SectionNotification>
        </dt>
      </dl>
    </div>
  );
};
