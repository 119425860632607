import React from 'react';
import s from '../../CartItem.scss';
import _ from 'lodash';

type OptionsProps = {
  itemId: number;
  options: {title: string; value: string}[];
  dataHook: string;
};

export const Options = ({itemId, options, dataHook}: OptionsProps) => {
  const maxValueLength = 35;

  const getValue = (value: string) => {
    if (value.length <= maxValueLength) {
      return value;
    }

    return _.truncate(value, {length: maxValueLength});
  };

  return (
    <ul className={s.options} data-hook={dataHook}>
      {options.map((option) => {
        return (
          option.value.trim() && (
            <li key={`option-item-${itemId}-value-${option.title}`}>
              {option.title}: {getValue(option.value)}
            </li>
          )
        );
      })}
    </ul>
  );
};
